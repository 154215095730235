<template>
	<div class="home">

		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
						<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
							ALERT NOTIFICATION SETTINGS
						</div>
					</v-card-title>

					<v-row class="pa-4">
						<v-col cols="12" sm="12" md="12" lg="12" xl="12">
							<v-row>
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-alert text  color="info" icon="mdi-cloud-alert" border="left">
										<p class="mb-0">Personalize the alert overview page by modify following params.</p>
									</v-alert>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-data-table dense :search="alertNotificationRuleData.search" :headers="alertNotificationRuleData.headers"
										:items="alertNotificationRuleData.dataSource" class="elevation-1" :single-select="false"
										v-model="alertNotificationRuleData.selectedData"
										:items-per-page="alertNotificationRuleData.itemPerPage">

										<template v-slot:item.console="{ item }">
											<v-autocomplete dense @change="(event) => alertNotificationRuleEventConsole(event, item)"
												:items="[{text: 'On', value: 1}, {text: 'Off', value: 0}]"
												item-text="text" item-value="value"
												:value="item.console"
												auto-select-first chips small-chips></v-autocomplete>
										</template>

										<template v-slot:item.subscribe="{ item }">
											<v-autocomplete dense @change="(event) => alertNotificationRuleEventSubscribe(event, item)"
												:items="[{text: 'On', value: 1}, {text: 'Off', value: 0}]"
												item-text="text" item-value="value"
												:value="item.subscribe"
												auto-select-first chips small-chips></v-autocomplete>
										</template>

										<template v-slot:item.overview="{ item }">
											<v-autocomplete dense @change="(event) => alertNotificationRuleEventOverview(event, item)"
												:items="[{text: 'On', value: 1}, {text: 'Off', value: 0}]"
												item-text="text" item-value="value"
												:value="item.overview"
												auto-select-first chips small-chips></v-autocomplete>
										</template>

									</v-data-table>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

				</v-card>
			</v-col>
		</v-row>

	</div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { snackbar } from "@components/mixins/snackbar";
import * as apiSettingsUserAlertRule from "@components/api/pk/settings/user-alert-rule";

export default {
    props: {
        selectedBrokerId: null,
        selectedBroker: null
    },
    components: {},
    mixins: [permission, snackbar],
    data () {
        return {
            alertNotificationRuleData: {
                dataSource: [
                    { name: "Big Lot", value: "big_lot", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Mandate", value: "mandate", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Profit Taker", value: "profit_taker", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Locking Position", value: "locking_position", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Trade On Credit", value: "trade_on_credit", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Large Exposure", value: "large_exposure", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Fast Trade", value: "fast_trade", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Watch List", value: "watch_list", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Tick Check", value: "tick_check", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Same Direction", value: "same_direction", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Volume Alert", value: "volume_alert", console: 1, menu: 1, subscribe: 1, overview: 1 },
                    { name: "Withdraw Deposit", value: "withdraw_deposit", console: 1, menu: 1, subscribe: 1, overview: 1 }
                ],
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Console", value: "console", width: "18%" },
                    { text: "Subscribe", value: "subscribe", width: "18%" },
                    { text: "Overview", value: "overview", width: "18%" }
                ],
                search: "",
                selectedData: [],
                itemPerPage: 15
            },
            rule: {
                big_lot: { volume: 1, bookExcept: "T,TEST" },
                mandate: { currentValue: 1, bookExcept: "T,TEST" },
                profit_taker: { profitToday: 1000, bookExcept: "T,TEST" },
                locking_position: { lockingPosition: 2.5, bookExcept: "T,TEST" },
                trade_on_credit: { equity: 1, credit: 1 },
                large_exposure: { exposure: 1, bookExcept: "T,TEST" },
                fast_trade: { volume: 1, bookExcept: "T,TEST" },
                watch_list: { logins: "" },
                tick_check: {},
                same_direction: { totalVolume: 1, bookExcept: "T,TEST", thresholdInterval: 100 },
                volume_alert: { volumeToday: 1, bookExcept: "T,TEST" }
            }
        };
    },
    methods: {
        /**
		 * alert notification规则的更新及读取
		 * @return {[type]} [description]
		 */
        alertNotificationRuleHandler () {
            const self = this;
            return {
                load () {
                    const params = { broker_id: self.selectedBrokerId };
                    apiSettingsUserAlertRule.fetch(params).then(res => {
                        console.log("apiSettingsUserAlertRule: ", res.data);
                        if (res.data.length != 0) {
                            self.alertNotificationRuleData.dataSource = res.data;
                            self.snackBarSuccess("Load Alert Rule Successfully");
                        }
                    });
                },
                update () {
                    const params = { broker_id: self.selectedBrokerId, rule: self.alertNotificationRuleData.dataSource };

                    apiSettingsUserAlertRule.update(params).then(res => {
                        const localData = JSON.parse(localStorage.getItem("alertNotificationSettings"));
                        const findIndex = localData.findIndex(item => item.broker_id == self.selectedBrokerId);
                        localData.splice(findIndex, 1);
                        const tempData = {
                            broker_id: self.selectedBrokerId,
                            broker: self.selectedBroker,
                            rule: self.alertNotificationRuleData.dataSource
                        };
                        localData.push(tempData);
                        localStorage.setItem("alertNotificationSettings", JSON.stringify(localData));
                        self.snackBarSuccess("Update Successfully");
                    });
                }
            };
        },
        /**
		 * 检测alert notification settings表格中select的变化
		 * @param  {[type]} data [description]
		 * @return {[type]}      [description]
		 */
        alertNotificationRuleEventConsole (event, item) {
            const value = item.value;
            const index = this.alertNotificationRuleData.dataSource.findIndex(item => item.value == value);
            this.alertNotificationRuleData.dataSource[index].console = event;
            this.alertNotificationRuleHandler().update();
        },
        /**
		 * 检测alert notification settings表格中select的变化
		 * @param  {[type]} data [description]
		 * @return {[type]}      [description]
		 */
        alertNotificationRuleEventMenu (event, item) {
            const value = item.value;
            const index = this.alertNotificationRuleData.dataSource.findIndex(item => item.value == value);
            this.alertNotificationRuleData.dataSource[index].menu = event;
            this.alertNotificationRuleHandler().update();
        },
        /**
		 * 检测alert notification settings表格中select的变化
		 * @param  {[type]} data [description]
		 * @return {[type]}      [description]
		 */
        alertNotificationRuleEventSubscribe (event, item) {
            const value = item.value;
            const index = this.alertNotificationRuleData.dataSource.findIndex(item => item.value == value);
            this.alertNotificationRuleData.dataSource[index].subscribe = event;
            this.alertNotificationRuleHandler().update();
        },
        /**
		 * 检测alert notification settings表格中select的变化
		 * @param  {[type]} data [description]
		 * @return {[type]}      [description]
		 */
        alertNotificationRuleEventOverview (event, item) {
            const value = item.value;
            const index = this.alertNotificationRuleData.dataSource.findIndex(item => item.value == value);
            this.alertNotificationRuleData.dataSource[index].overview = event;
            this.alertNotificationRuleHandler().update();
        }
    },
    mounted () {
        this.alertNotificationRuleHandler().load();
    }
};
</script>
