<template>
	<div class="home">
		<v-card class="full-height-card">
			<v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>
				<v-row>
					<v-col cols="12">
						<v-tabs>
							<v-tab v-on:click="changeTab('security')">Security</v-tab>
							<v-tab v-on:click="changeTab('preference')" v-if="spSitePreference == 1">Site Preference</v-tab>
						</v-tabs>
					</v-col>
				</v-row>
				<v-row><v-col cols="12"></v-col></v-row>
				<Security v-if="selectedKey == 'security'" />
				<SitePreference v-if="selectedKey == 'preference' && spSitePreference == 1" />
			</v-container>
		</v-card>
	</div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import Security from "./components/ComponentSecurity";
import SitePreference from "./components/ComponentSitePreference";
import Config from "./components/ComponentConfig";
import { permission } from "@components/mixins/permission";
export default {
    components: { Breadcrumbs, Security, Config, SitePreference },
    mixins: [permission],
    data () {
        return {
            permission: [],
            selectedKey: "security",
            spSitePreference: 0
        };
    },
    methods: {
        changeTab (key) {
            this.selectedKey = key;
        }
    },
    mounted () {
        const userFunction = this.getFunction("account");

        if (userFunction.includes("spSitePreference")) {
            this.spSitePreference = 1;
        }
    }
};
</script>
