<template>
	<div class="home">

		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
						<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
							BROKER FILTER
						</div>
					</v-card-title>

					<v-row class="pa-4">
						<v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="brokerMap.length > 1">
							<v-autocomplete label="Filter Broker"
								v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
								auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<SPBook :selectedBrokerId="selectedBrokerId" v-if="loadComponent"/>

		<SPAlert :selectedBrokerId="selectedBrokerId" :selectedBroker="selectedBroker" v-if="loadComponent && loadSpAlertConfig == 1"/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import SPBook from "./ComponentSitePreferenceBook";
import SPAlert from "./ComponentSitePreferenceAlert";

export default {
    components: { SPBook, SPAlert },
    mixins: [permission, helper],
    data () {
        return {
            loadComponent: false,
            selectedBrokerId: -1,
            selectedBroker: "",
            loadSpAlertConfig: 0
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    watch: {
        /**
		 * 监控broker id的变化
		 * @param  {[type]} nv [description]
		 * @param  {[type]} ov [description]
		 * @return {[type]}    [description]
		 */
        selectedBrokerId (nv, ov) {
            this.selectedBroker = this.getBrokerNameById(nv);

        	this.loadComponent = false;

        	this.$nextTick(() => {
        		this.loadComponent = true;
        	});
        }
    },
    methods: {
    },
    mounted () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;

        // 初始化组件
        this.loadComponent = true;

        const userFunction = this.getFunction("account");
        if (userFunction.includes("spAlertConfig")) this.loadSpAlertConfig = 1;
    }
};
</script>
