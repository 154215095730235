import request from "@utils/pk/request";

export function fetch (query) {
    return request({
        url: "settings/user-config",
        method: "GET",
        params: query
    });
}

export function create (query) {
    return request({
        url: "settings/user-config",
        method: "POST",
        data: query
    });
}

export function update (query) {
    return request({
        url: "settings/user-config",
        method: "PUT",
        data: query
    });
}
