<template>
	<div class="home">

		<v-row>
	        <v-col cols="12" sm="12" md="12" lg="6" xl="6" v-if="loadSpSiteBook == 1">

				<v-row>
					<v-col cols="12">
						<v-card outlined>
							<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
								<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
									BOOK HIDE PREFERENCE
								</div>
							</v-card-title>

							<v-row class="pa-4">
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-alert text  color="info" icon="mdi-cloud-alert" border="left">
										<p class="mb-0">The selected book will not be shown throughout the entire website.</p>
									</v-alert>
								</v-col>

								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-autocomplete label="Hide Book (All of selected book will not be shown on this site)"
										@change="onBookHideChange"
										v-model="valueHidebook" :items="filterBookLists" item-text="book" item-value="book"
										auto-select-first multiple chips deletable-chips small-chips></v-autocomplete>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
		    </v-col>

	        <v-col cols="12" sm="12" md="12" lg="6" xl="6" v-if="loadSpAlertBook == 1">
				<v-row>
					<v-col cols="12">
						<v-card outlined>
							<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
								<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
									ALERT BOOK HIDE PREFERENCE
								</div>
							</v-card-title>

							<v-row class="pa-4">
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-alert text  color="info" icon="mdi-cloud-alert" border="left">
										<p class="mb-0">The selected book will not be shown either in alert notification and overview.</p>
									</v-alert>
								</v-col>

								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-autocomplete label="Hide Book (All of selected book will not be shown on this site)"
										@change="onBookAlertChange"
										v-model="alertHideBook" :items="filterBookLists" item-text="book" item-value="book"
										auto-select-first multiple chips deletable-chips small-chips></v-autocomplete>
								</v-col>
							</v-row>

						</v-card>
					</v-col>
				</v-row>
		    </v-col>

		</v-row>
	</div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { snackbar } from "@components/mixins/snackbar";
import * as apiSettingsUserConfig from "@components/api/pk/settings/user-config";
import * as apiBook from "@components/api/pk/book";

export default {
    props: {
        selectedBrokerId: null
    },
    components: {},
    mixins: [permission, snackbar],
    data () {
        return {
            btnLoading: false,
            filterBookLists: [],
            valueHidebook: [],
            alertHideBook: [],
            loadSpSiteBook: 0,
            loadSpAlertBook: 0
        };
    },
    methods: {

        onBookHideChange (data) {
            this.userConfigHandler().update("hidebook", data);
        },
        onBookAlertChange (data) {
            this.userConfigHandler().update("hidealert", data);
        },
        /**
         * Fetch users book group mapping
         * @return {[type]}
         */
        loadBook (ignoreHidebook = null) {
            const self = this;
            let params = {};

            if (ignoreHidebook !== null) params = { broker_id: this.selectedBrokerId, ignore_hidebook: 1 };
            if (ignoreHidebook === null) params = { broker_id: this.selectedBrokerId };

            console.log(ignoreHidebook, " ==> ", params);

            apiBook.fetch(params).then(res => {
                if (ignoreHidebook === null) {
                	console.log(res.data);
                	// 这里修改本地localstorage的bm列表
                	let data = JSON.parse(localStorage.getItem("bm"));
                	data = data.filter(item => item.broker_id != self.selectedBrokerId);
                	for (let i = 0; i < res.data.length; i++) {
                		data.push({ broker_id: self.selectedBrokerId, book: res.data[i].book });
                	}
                	localStorage.setItem("bm", JSON.stringify(data));
                } else {
	                self.filterBookLists = res.data;
	                if (res.data.length != 0) {};
                }
            });
        },
        userConfigHandler () {
        	const self = this;

        	return {
        		load (configName) {
        			const params = { broker_id: self.selectedBrokerId, config_name: configName };
        			apiSettingsUserConfig.fetch(params).then(res => {
        				if (configName == "hidebook") {
        					self.valueHidebook = [];
        					self.valueHidebook = res.data.value;
                            // console.log(res.data);
        				}

        				if (configName == "hidealert") {
        					self.alertHideBook = [];
        					self.alertHideBook = res.data.value;
        				}
        			});
        		},
        		update (configName, configValue) {
        			const params = { broker_id: self.selectedBrokerId, config_name: configName, config_value: configValue };

        			apiSettingsUserConfig.update(params).then(res => {
        				if (configName == "hidealert") {
                            console.log(configValue);
                            let alertHideBroker = localStorage.getItem("alertHideBroker");
                            if (alertHideBroker != undefined) {
                                alertHideBroker = JSON.parse(alertHideBroker);
                                if (alertHideBroker.hasOwnProperty(self.selectedBrokerId)) {
                                    alertHideBroker[self.selectedBrokerId] = configValue;
                                } else {
                                    alertHideBroker[self.selectedBrokerId] = [];
                                    alertHideBroker[self.selectedBrokerId] = configValue;
                                }
                                localStorage.setItem("alertHideBroker", JSON.stringify(alertHideBroker));
                            } else {
                                alertHideBroker = {};
                                alertHideBroker[self.selectedBrokerId] = configValue;
                                localStorage.setItem("alertHideBroker", JSON.stringify(alertHideBroker));
                            }
                            localStorage.setItem("alertHide", JSON.stringify(configValue));
        				}

        				if (configName == "hidebook") {
        					self.loadBook();
        				}

        				self.snackBarSuccess("Update successfully");
        			});
        		}
        	};
        }
    },
    mounted () {
        this.loadBook(1);

        const userFunction = this.getFunction("account");
        if (userFunction.includes("spSiteBook")) {
	        this.userConfigHandler().load("hidebook");
        	this.loadSpSiteBook = 1;
        }
        if (userFunction.includes("spAlertBook")) {
        	this.userConfigHandler().load("hidealert");
        	this.loadSpAlertBook = 1;
        }
    }
};
</script>
